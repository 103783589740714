<template>
  <form ref="form" @submit.prevent="submit">
    <fieldset ref="fields" :disabled="loading">
      <slot name="fields"/>
    </fieldset>
    <div ref="buttons" class="form-group">
      <div v-if="error" class="invalid-feedback invalid-feedback--buttons">{{ error }}</div>
      <button type="submit" ref="submit" class="button button--loader" :class="{ 'loading': loading }">
        <inline-svg :src="require('@/assets/spinner.svg')" stroke="currentColor" width="16" height="16" class="preloader"/>
        <span>{{ buttonText }}</span>
      </button>
      <slot name="buttons"/>
    </div>
  </form>
</template>

<script>
import { httpPost } from '@/http'
import { duplicateFields } from '@/utils'

export default {
  emits: ['post', 'done', 'error'],
  props: {
    /**
     * URL to post to when form is submit
     */
    url: {
      type: String,
      required: true,
    },
    /**
     * Text to show in the primary submit button of the form
     */
    buttonText: {
      type: String,
      default: 'Submit',
    },
  },
  data() {
    return {
      controller: null,
      loading: false,
      error: null,
      fieldErrors: [],
      duplicated: [],
    };
  },
  beforeUnmount() {
    this.abort();
    this.destroyDuplicateFields();
  },
  methods: {
    abort() {
      this.controller?.abort();
      this.loading = false;
      this.error = null;
      this.fieldErrors = [];
      this.controller = null;
    },
    destroyDuplicateFields() {
      for (const { el, cloned } of this.duplicated) {
        cloned.remove();
        el.style.display = null;
      }

      this.duplicated = [];
    },
    submit() {
      this.controller = new AbortController();
      this.loading = true;
      this.$refs.buttons.classList.remove('invalid');

      // clear all error fields
      this.fieldErrors.forEach(el => el.classList.remove('invalid'));
      this.fieldErrors = [];

      const formData = new FormData(this.$refs.form);
      this.$emit('post', formData);

      this.duplicated = duplicateFields(formData, this.$refs.form);

      httpPost(this.url, formData, { signal: this.controller.signal })
        .then((res) => {
          const { status, fields } = res;

          if (status !== 'ok') {
            // result contains fields for us to mark as invalid
            if (fields) {
              const children = Array.from(this.$refs.fields.elements);

              fields.forEach((field) => {
                const el = children.find(child => child.id === field);
                if (el) {
                  el.parentNode.classList.add('invalid');
                  this.fieldErrors.push(el.parentNode);
                }
              });
            }

            // throw empty error if we have fields set, as we'll be showing custom field errors,
            // we don't want to show the general error message. (shown if this.error != null)
            throw new Error(fields ? '' : status);
          }

          this.$emit('done', { result: res, formData });
        })
        .catch((err) => {
          this.error = err.message;
          this.$refs.buttons.classList.add('invalid');
          this.$emit('error', this.error);
        })
        .finally(() => {
          this.loading = false;
          this.destroyDuplicateFields();
        });
    },
  },
}
</script>