<template>
  <div class="login__container">
    <template v-if="showSuccessMessage">
      <h2>Success</h2>
      <p>We've sent an email to <strong>{{ emailAddress }}</strong> containing a single-use "magic link" to access the system - or if you don't currently have an account it will detail how to get one with us.</p>
    </template>
    <template v-else>
      <h2>Client Login</h2>
      <p>To log into your account, please enter your work email address and we'll send you a single-use "magic link" as an email that will log you into the system.</p>
      <ajax-form :url="`/login/client`" buttonText="Login" @done="done" class="mt-3">
        <template #fields>
          <div ref="email_address" class="form-group">
            <label for="email_address">Email address <abbr class="required">*</abbr></label>
            <input type="email" name="email_address" id="email_address" class="form-control" required/>
            <div class="invalid-feedback">Please enter a valid Email address.</div>
          </div>
        </template>
      </ajax-form>
    </template>
  </div>
</template>

<script>
import AjaxForm from '@/components/ajax-form.vue'

export default {
  components: {
    AjaxForm,
  },
  data() {
    return {
      emailAddress: null,
      showSuccessMessage: false,
    };
  },
  methods: {
    done({ formData }) {
      this.emailAddress = formData.get('email_address');
      this.showSuccessMessage = true;
    },
  },
}
</script>